import React, { useEffect, Fragment } from "react";
import Card from '@material-ui/core/Card';
import { makeStyles } from '@material-ui/core/styles';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import _ from 'lodash';

// @ts-ignore
import styles from "./InnerBlog.module.css";
import { withLayout } from "../Layout";

const useStyles = makeStyles({
  root: {
    backgroundColor: 'transparent',
    marginTop: 20,
    marginBottom: 20,
    alignItems: 'center',
    justifyContent: 'center',
    width: '80%'
  },
  title: {
    color: '#fbed21',
    fontFamily: `"Barrio", sans-serif`,
    textAlign: 'center',
    fontSize: '30px',
    width: '100%',
  },
  content: {
    color: 'white',
    fontFamily: `Josefin Sans`,
    fontSize: '20px',
  },
  gridItem: {
    display: 'flex'
  },
  container: {
  }
});

const InnerBlog = ({ pageContext }) => {
  console.info('pageContext', pageContext);
  console.info(_.get(pageContext, 'data.description.description', ''));
  const classes = useStyles();
  return (
    <div className={styles.sectionWrapper}>
      <p className={styles.blogHeader}>BLOG</p>
      <div className={styles.blogItemsContainer}>
        <Grid container justify="space-around" direction="row" className={classes.container}>
          <Grid item xs={12} sm={8} justify="center" className={classes.gridItem}>
            <Card className={styles.root}>
              <CardMedia
                className={styles.media}
                image={_.get(pageContext, 'data.heroImage.file.url', '')}
              />
              <CardContent className={styles.cardContent}>
                <Typography gutterBottom variant="h5" component="h2" className={classes.title}>
                {_.get(pageContext, 'data.title', '')}
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p" className={classes.content}>
                  {_.get(pageContext, 'data.body.childMarkdownRemark.internal.content', '')}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default withLayout(InnerBlog);
